import { CommonModule } from '@angular/common';
import { Component, computed, HostListener } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from '@libs/app.services/landing/config.service';
import { Observable } from 'rxjs';
import { TidioButtonComponent } from '@libs/stand-alone-components/commons/tidio-button/tidio-button.component';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { RoutesService } from '@libs/app.services/landing/routes.service';
import { RouterModule } from '@angular/router';
import { ToastComponent } from '@libs/stand-alone-components/commons/toast/toast.component';
import { ModalComponent } from '@libs/stand-alone-components/commons/modal/modal.component';
import { SpinnerComponent } from '@libs/stand-alone-components/commons/spinner/spinner';
import { RouterLoaderComponent } from '@libs/stand-alone-components/commons/router-loader/router-loader.component';
import { BoxesSpinnerComponent } from '@libs/stand-alone-components/commons/boxes-spinner/boxes-spinner.component';
import { DeviceService } from '@libs/app.services/device.service';
import { TelegramService } from '@libs/app.services/account/telegram.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TidioButtonComponent,
    ToastComponent,
    RouterLoaderComponent,
    RouterModule,
    ModalComponent,
    SpinnerComponent,
    BoxesSpinnerComponent,
  ],
})
export class AppComponent {
  config$: Observable<any> = this.configService.init();
  selectAsideRight$: Observable<boolean> = this.settingService.selectAsideRight();
  selectAsideLeft$: Observable<boolean> = this.settingService.selectAsideLeft();
  tidioAvailable = computed(
    () =>
      (this.routesService.route() == '/' ||
        this.routesService.route() == '/app' ||
        this.routesService.route() == '/casino') &&
      !this.telegramService.telegramMiniApp(),
  );
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    this.deviceService.displayWidth.set(innerWidth);
    document.documentElement.style.setProperty('--display-w', `${innerWidth}px`);
  }
  constructor(
    private configService: ConfigService,
    private settingService: StoreSettingService,
    private routesService: RoutesService,
    private deviceService: DeviceService,
    private telegramService: TelegramService,
  ) {
    setInterval(() => this.onResize(), 1000);
  }
}
