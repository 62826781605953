import { StoreDevtoolsModule } from '@ngrx/store-devtools';
declare let process: any;

export const StoreDevModules =
  process.env.NODE_ENV === 'development'
    ? [
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: true, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          connectInZone: true,
        }),
      ]
    : [];
