import { ISiteNotification, SITE_NOTIFICATION_STATE, SITE_NOTIFICATION_TYPE } from "@libs/interfaces";
import { createReducer, on } from "@ngrx/store";
import { assign, findIndex, take, takeRight } from 'lodash'
import { addNotification, addNotifications, setFiltersNotification, setReadNotification } from "./actions";
import { INotificationsState } from "./model";

const initialState: Readonly<INotificationsState> = {
  notifications: [],
  filters: {
    type: SITE_NOTIFICATION_TYPE.PLATFORM,
    state: 'ALL'
  }
};
export const notificationsReducer = createReducer(
  initialState,
  on(addNotifications, (state, { notifications }) => assign({}, state, { notifications: notifications })),
  on(addNotification, (state, { notification }) => assign({}, state, { notifications: [notification, ...state.notifications] })),
  on(setReadNotification, (state, { notification }) => {
    const index = findIndex(state.notifications, (n: ISiteNotification) => n._id == notification._id)
    if (index == -1)
      return state
    const notif = assign({}, state.notifications[index], {
      state: SITE_NOTIFICATION_STATE.READED,
      readedAt: Date.now()
    })
    const first = take(state.notifications, index)
    const last = takeRight(state.notifications, state.notifications.length - (index + 1))
    return assign({}, state, { notifications: [...first, notif, ...last] })
  }),
  on(setFiltersNotification, (state, { filters }) => assign({}, state, { filters: filters })),
)