import {
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
  isDevMode,
} from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideServiceWorker } from '@angular/service-worker';
import { TranstalerLoader } from '@libs/helper/translater.helper';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AuthInterceptor } from '@libs/interceptors/client.auth.interceptor';
// Services
import { LocalstorageService } from '@libs/app.services/landing/localstorage.service';
import { AuthService } from '@libs/app.services/landing/auth.service';
import { UserService } from '@libs/app.services/account/user.service';
import { AwakeService } from '@libs/app.services/awake.service';
import { LangService } from '@libs/app.services/lang.service';
import { ApiService } from '@libs/app.services/api.service';
import { UpdateService } from '@libs/app.services/app.updates.service';
import { DeviceService } from '@libs/app.services/device.service';
import { ScheduleService } from '@libs/app.services/bicho/schedule-selector.service';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { BonusService } from '@libs/app.services/wallet/bonus.service';
import { CasinoConfigService } from '@libs/app.services/casino/casino-config.service';
// Store
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@libs/store/landing.state';
import { BettingEffects } from '@libs/store/betting/effects';
import { GamesEffects } from '@libs/store/game/effects';
import { WalletEffects } from '@libs/store/wallet/effects';
import { RoutesService } from '@libs/app.services/landing/routes.service';
import { ConfigService } from '@libs/app.services/landing/config.service';
import { RefererService } from '@libs/app.services/landing/referer.service';
import { WalletService } from '@libs/app.services/wallet/wallet.service';
import { UserStoreService } from '@libs/app.services/store/user-store.service';
import { StoreDevModules } from '@libs/store/store-dev-module';
import { TelegramService } from '@libs/app.services/account/telegram.service';
export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(
      TranslateModule.forRoot(TranstalerLoader),
      EffectsModule.forRoot(BettingEffects, GamesEffects, WalletEffects),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideServiceWorker('./sw.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideAnimationsAsync(),
    provideRouter(appRoutes, withViewTransitions()),
    provideEnvironmentNgxMask(),
    provideStore(reducers),
    importProvidersFrom(StoreDevModules),
    DeviceService,
    ApiService,
    AuthService,
    UserService,
    LangService,
    AwakeService,
    UpdateService,
    RoutesService,
    ConfigService,
    ScheduleService,
    BonusService,
    CasinoConfigService,
    RefererService,
    WalletService,
    StoreSettingService,
    LocalstorageService,
    UserStoreService,
    TelegramService,
  ],
};
