import { CHAT_CHANNEL } from "@libs/interfaces";
import { createReducer, on } from "@ngrx/store";
import { assign, head, takeRight } from "lodash";
import { addGiftCollection, addMention, addMessage, addMessages, setChannel, setEmogiOpen, setGif, setGifOpen, setGiftOffset, setGiftType, toggleEmogiOpen, toggleMessages } from "./actions";
import { GIF_TYPE, IChatState } from "./model";
const id_local_store_channel = 'CHAT_CHANNEL'
const getStorage = (): any => localStorage.getItem(id_local_store_channel)
const setStorage = (channel: string) => {
  localStorage.setItem(id_local_store_channel, channel)
  return channel
}

const initialState: Readonly<IChatState> = {
  channels: Object.values(CHAT_CHANNEL),
  channel: getStorage() || head(Object.values(CHAT_CHANNEL)),
  messages: [],
  active: false,
  gifts: {
    offset: 0,
    type: GIF_TYPE.TRENDING,
    collection: [],
    selected: undefined
  },
  gif: undefined,
  emojiOpen: false,
  gifOpen: false,
  mention: ''
}
export const sessionChatReducer = createReducer(
  initialState,
  on(setChannel, (state, { channel }) => assign({}, state, { channel: setStorage(channel) })),
  on(addMessage, (state, { message }) => assign({}, state, { messages: takeRight([...state.messages, message], 100) })),
  on(addMessages, (state, { messages }) => assign({}, state, { messages: messages })),
  on(addMention, (state, { mention }) => assign({}, state, { mention: mention })),
  on(toggleMessages, (state) => assign({}, state, { active: !state.active })),
  on(setGiftType, (state, { topic }) => assign({}, state, { gifts: assign({}, state.gifts, { type: topic, collection: [], offset: 0 }) })),
  on(setGiftOffset, (state, { offset }) => assign({}, state, { gifts: assign({}, state.gifts, { offset: offset }) })),
  on(addGiftCollection, (state, { collection }) => assign({}, state, { gifts: assign({}, state.gifts, { collection: [...state.gifts.collection, ...collection] }) })),
  on(setGif, (state, { gif }) => assign({}, state, { gif: gif })),
  on(setEmogiOpen, (state, { value }) => assign({}, state, { emojiOpen: value })),
  on(toggleEmogiOpen, (state,) => assign({}, state, { emojiOpen: !state.emojiOpen })),
  on(setGifOpen, (state, { value }) => assign({}, state, { gifOpen: value })),
)