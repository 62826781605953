import { Component, ElementRef, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, tap, timer } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-router-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './router-loader.component.html',
  styleUrl: './router-loader.component.scss',
})
export class RouterLoaderComponent implements OnInit {
  bar = viewChild<ElementRef>('bar');
  loading = signal(false);
  max_time = 3000;
  num_intervals = 3;
  timer$ = timer(0, 1000).pipe(
    map(i => {
      const interval = Math.floor(i / this.num_intervals) % this.num_intervals; // 0, 1, or 2
      const timeInInterval = i % this.num_intervals;
      return (((interval + 1) * 1000 + timeInInterval * 1000) / this.max_time) * 100;
    }),
    map(value => Math.min(value, 100)),
    tap(value => {
      this.bar().nativeElement.style.setProperty('--bar-width', `${value}%`);
    }),
  );
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.events.pipe(tap(e => this.navigationInterceptor(e))).subscribe();
  }
  navigationInterceptor(event): void {
    if (event instanceof NavigationStart) {
      this.loading.set(true);
      setTimeout(() => {
        this.loading.set(false);
      }, 3000);
    }
    if (event instanceof NavigationEnd) {
      this.loading.set(false);
    }
  }
}
